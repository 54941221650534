<template>
  <div>
    <v-card min-height="220">
      <v-card-text>
        <h3>
          {{ test.name }}
        </h3>
        <h4 class="text-body-2">
          {{ test.description }}
        </h4>
        <v-divider class="my-1"></v-divider>
        <v-row dense class="mt-2 mb-3">
          <v-col>
            <v-chip
              v-for="(tag, index) in compTags"
              small
              label
              outlined
              color="primary"
              text-color="primary"
              :key="index"
              class="mr-1"
              >{{ tag }}</v-chip
            >
            <v-chip
              small
              label
              outlined
              color="red"
              text-color="red"
              class="float-right"
            >
              {{ `${$t(`questions`)} ${compQuestionsCount}` }}</v-chip
            >
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn height="60" block color="primary" dark @click="doTest">{{
              $t("do-test")
            }}</v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn outlined block color="black" dark @click="editTest">{{
              $t("edit")
            }}</v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="text-right text-caption">
            <!--  <v-chip
              small
              label
              outlined
              color="secondary"
              text-color="secondary"
              class="d-block text-center"
            > -->
            {{ `${$t("author")}: ${compUserName}` }}
            <!-- </v-chip> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mr-1"> </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MainCardTestsComp",
  props: ["test"],
  computed: {
    compTags() {
      if (this.test.tags === "") {
        return "";
      }
      const tmp = this.test.tags.split(",");
      return tmp;
    },
    compQuestionsCount() {
      return this.test.question_test_count;
    },
    compUserName() {
      return this.test.user_test.name;
    },
  },
  methods: {
    doTest() {
      this.$router.push({ path: `/do-test/${this.test.id}` });
    },
    editTest() {
      this.$router.push({ path: `/edit-test/${this.test.id}` });
    },
  },
};
</script>

<style lang="scss" scoped></style>
