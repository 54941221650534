<template>
  <div>
    <h1 class="py-3">{{ $t("test-list") }}</h1>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(test, index) in testsList"
        :key="index"
      >
        <main-card-tests-comp :test="test"></main-card-tests-comp>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainCardTestsComp from "./parts/MainCardTestsComp.vue";
import { doTestSrv } from "@/_services/dotest.service";

export default {
  name: "main-comp",
  components: { MainCardTestsComp },
  data: () => ({
    testsList: null,
  }),
  methods: {
    async getTests() {
      const res = await doTestSrv.getAllTests();
      // console.log(res);
      this.testsList = res.data.tests;
    },
  },
  mounted() {
    this.getTests();
  },
};
</script>

<style lang="scss" scoped></style>
